import React from "react";
import classnames from "classnames";

import MainMenu from "../common/MainMenu";

import * as css from "./Header.module.css";

const Header = ({ title, logo, progress, children, className }) => {
  return (
    <header className={classnames(css.root, className)}>
      <MainMenu logo={logo} title={title} color={"blue"} progress={progress} />
      <div className={css.titleBlock}>
        <h2>{title}</h2>
        {children}
      </div>
    </header>
  );
};

export default Header;

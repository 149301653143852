import * as React from "react";
import { graphql } from "gatsby";
import classnames from "classnames";

import Layout from "../components/Layout";
import Header from "../components/docs/Header";
import Footer from "../components/common/Footer";
import Subscribe from "../components/common/Subscribe";

import { useScrollProgress } from "../utils/hooks";

import * as grid from "../style/grid.module.css";
import * as css from "../style/about.module.css";

const About = ({ data }) => {
  const progress = useScrollProgress();
  return (
    <Layout className={css.root}>
      <Header logo={data.logo} title={"About"} progress={progress}>
        <p>
          Mechanic* is a powerful design production toolchain that helps
          forward-looking organizations move away from a manual design workflow by
          automating their design operations. Define input parameters for your
          design functions to auto-generate a user interface, batch-create
          multiple versions of a design, and model the API endpoints.
        </p>
      </Header>
      <main className={classnames(grid.grid, css.main)}>
        <div className={css.team}>
          <h2>Team</h2>
          <div>
            Mechanic was built by{" "}
            <a href="https://designsystems.international/">
              Design Systems International
            </a>
            .
          </div>
        </div>
        <Subscribe variant={"blue"} />
      </main>
      <Footer variant={"blue"} />
    </Layout>
  );
};

export default About;

export const query = graphql`
  query {
    logo: file(name: { eq: "logo" }) {
      childImageSharp {
        gatsbyImageData(width: 30)
      }
    }
  }
`;
